import { FormEvent, useState } from "react";

export default function EmailForm() {
  const [responseMessage, setResponseMessage] = useState("");

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // @ts-ignore
    window.posthog.capture("subscribe_button_clicked");
    const formData = new FormData(e.currentTarget);
    const email = formData.get("email");
    const response = await fetch("/api/subscribe", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });
    if (response.ok) {
      setResponseMessage("Thank you for subscribing!");
    }
  };
  const subscribe = (
    <>
      <label className="sr-only">Email address</label>
      <input
        autoComplete="email"
        className="block w-full h-10 px-4 py-2 placeholder-gray-300 duration-200 border rounded-lg appearance-none bg-cyan-800 border-cyan-600 bg-chalk focus:border-gray-300 focus:outline-none focus:ring-gray-300 sm:text-sm text-cyan-500"
        id="email-address"
        name="email"
        placeholder="Enter your email"
        required
        type="email"
      />
      <button
        className="flex items-center justify-center h-10 px-4 py-2 text-sm font-semibold text-white transition-all rounded-lg bg-gradient-to-b from-cyan-500 hover:to-cyan-700 to-cyan-600"
        type="submit"
      >
        Subscribe
      </button>
    </>
  );
  return (
    <form className="w-full lg:max-w-sm mx-auto lg:mx-0" onSubmit={submit}>
      <div className="flex flex-col gap-2 lg:flex-row">
        {responseMessage ? (
          <p className="text-2xl text-cyan-300 text-center">
            {responseMessage}
          </p>
        ) : (
          subscribe
        )}
      </div>
    </form>
  );
}
